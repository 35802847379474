import { Injectable } from '@angular/core';
import { PermissionService } from '@services/internal/permission.service';
import { EventModel, UserModel } from '@shared/models';
import { InjectorContainerModule } from '@shared/helpers/injector-container/injector-container.module';
import { UsersService } from '@services/users.service';

@Injectable()
export class EventActionsService {
  static currentUser: UserModel;

  static destroy() {
    EventActionsService.currentUser = null;
  }

  static canGenerateInvoice(event: EventModel): boolean {
    if (!EventActionsService.checkCurrentUserPermissions(event)) {
      return false;
    }
    const permissionService: PermissionService = InjectorContainerModule.injector.get(PermissionService);
    return (
      (event.eventStatus === 'COMPLETED' || event.eventStatus === 'PAID') &&
      event.eventType !== 'DAMAGE' &&
      event.eventType !== 'RETURN' &&
      !event.facilityStockEvent &&
      ((permissionService.isGranted('events', 'canGenerateInvoice') &&
        permissionService.isGranted('events', 'canSendInvoiceAsAttachment')) ||
        permissionService.isGranted('events', 'downloadInvoiceAndSOFInReadOnlyMode'))
    );
  }

  // Parameter `hidden` used for hiding the Generate SO button for events in PENDING PAYMENT status for table and board views
  static canGenerateSalesOrder(event: EventModel, hidden: boolean = false): boolean {
    if (!EventActionsService.checkCurrentUserPermissions(event)) {
      return false;
    }
    const permissionService: PermissionService = InjectorContainerModule.injector.get(PermissionService);
    return (
      (event.eventStatus === 'CLOSED' || (!hidden && (event.eventStatus === 'COMPLETED' || event.eventStatus === 'PAID'))) &&
      (permissionService.isGranted('events', 'canSendSalesOrderAsAttachment') ||
        permissionService.isGranted('events', 'downloadInvoiceAndSOFInReadOnlyMode'))
    );
  }

  static canDuplicateEvent(event: EventModel): boolean {
    if (!EventActionsService.checkCurrentUserPermissions(event)) {
      return false;
    }
    const permissionService: PermissionService = InjectorContainerModule.injector.get(PermissionService);
    return (
      permissionService.isGranted('events', 'copyEvent') &&
      (event.eventType !== 'STOCK_ADJUSTMENT' ||
        (event.eventType === 'STOCK_ADJUSTMENT' &&
          permissionService.isGranted('userLevel', 'invDeactivationAndStockEventCreationAllowed'))) &&
      !event?.facility?.isCreditHold
    );
  }

  static canAddPONumber(event: EventModel): boolean {
    if (!EventActionsService.checkCurrentUserPermissions(event)) {
      return false;
    }
    const permissionService: PermissionService = InjectorContainerModule.injector.get(PermissionService);
    return (
      permissionService.isGranted('events', 'canAddPONumber') &&
      event.eventStatus === 'CLOSED' &&
      event.eventType !== 'DAMAGE' &&
      event.eventType !== 'RETURN' &&
      !event?.representative?.isOffline
    );
  }

  static canExportTOQuickBooks(event: EventModel): boolean {
    if (!EventActionsService.checkCurrentUserPermissions(event)) {
      return false;
    }
    const permissionService: PermissionService = InjectorContainerModule.injector.get(PermissionService);
    const isQuickBooksEnabled = UsersService.getUser().organization.isQuickBooksEnabled;
    return (
      isQuickBooksEnabled &&
      !!event?.facility?.customerId?.length &&
      permissionService.isGranted('events', 'canExportSalesOrderFormToQuickBooks') &&
      (event.eventStatus === 'CLOSED' || event.eventStatus === 'COMPLETED' || event.eventStatus === 'PAID')
    );
  }

  static canExportTOXero(event: EventModel): boolean {
    if (!EventActionsService.checkCurrentUserPermissions(event)) {
      return false;
    }
    const permissionService: PermissionService = InjectorContainerModule.injector.get(PermissionService);
    const isQuickBooksEnabled = UsersService.getUser().organization.isXeroEnabled;
    return (
      isQuickBooksEnabled &&
      !!event?.facility?.xeroCustomerId?.length &&
      permissionService.isGranted('events', 'canExportSalesOrderFormToQuickBooks') &&
      (event.eventStatus === 'CLOSED' || event.eventStatus === 'COMPLETED' || event.eventStatus === 'PAID')
    );
  }

  static canCreateInventoryRequest(event: EventModel): boolean {
    if (!EventActionsService.checkCurrentUserPermissions(event)) {
      return false;
    }
    const permissionService: PermissionService = InjectorContainerModule.injector.get(PermissionService);
    return (
      permissionService.isGranted('inventory', 'canInitiateInventoryRequest') &&
      event.eventStatus === 'OPEN' &&
      event.eventType !== 'STOCK_ADJUSTMENT'
    );
  }

  static canCreateReplenishmentRequest(event: EventModel): boolean {
    if (!EventActionsService.checkCurrentUserPermissions(event)) {
      return false;
    }
    const permissionService: PermissionService = InjectorContainerModule.injector.get(PermissionService);
    return (
      permissionService.isGranted('inventory', 'canInitiateInventoryRequest') &&
      (event.eventStatus === 'CLOSED' || event.eventStatus === 'COMPLETED' || event.eventStatus === 'PAID')
    );
  }

  static canViewQuotesTab(event: EventModel): boolean {
    if (!EventActionsService.checkCurrentUserPermissions(event)) {
      return false;
    }
    return event.eventType === 'CASE' || event.eventType === 'STOCKING_ORDER';
  }

  static canViewDeviceUserTab(event: EventModel): boolean {
    return EventActionsService.checkCurrentUserPermissions(event);
  }

  static canReopenEvent(event: EventModel): boolean {
    return EventActionsService.checkCurrentUserPermissions(event);
  }

  static caMarkDevicesToEvent(event: EventModel): boolean {
    return EventActionsService.checkCurrentUserPermissions(event);
  }

  static canMarkPaid(event: EventModel): boolean {
    if (!EventActionsService.checkCurrentUserPermissions(event)) {
      return false;
    }
    const permissionService: PermissionService = InjectorContainerModule.injector.get(PermissionService);
    return permissionService.isGranted('events', 'canMarkAsPaid') && event.eventStatus === 'COMPLETED';
  }

  static checkCurrentUserPermissions(event: EventModel): boolean {
    if (!this.currentUser) {
      this.currentUser = UsersService.getUser();
    }
    if (this.currentUser.role === 'FACILITY_MANAGER' && this.currentUser.id !== event.representative?.id) {
      return false;
    }
    return true;
  }
}
