import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TIconColor, TIcons } from '@shared/type/icons.type';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class IconComponent {
  @Input() icon: TIcons = null;
  @Input() color: TIconColor = 'default';
  @Input() type: 'filled' | 'outline' = 'filled';
  @Input() size: 16 | 18 | 20 | 24 = 20;
  @Input() defaultCursor: boolean = false;
  @Input() dataCy: string = '';
  @Input() changeColorOnHover: boolean = false;
  @Input() shakeAnimation: boolean = false;
  @Input() disabled: boolean = false;
  @Input() background: boolean = false;

  @Output() clickEmitter = new EventEmitter<void>();

  getBackgroundColor(): string {
    // @TODO Add all colors here if needed
    if (this.color === 'blue') {
      return 'backgroundBlue';
    } else {
      return '';
    }
  }
}
