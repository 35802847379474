import { Injectable, Injector, inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UserModel, UserTimeZone } from '@shared/models/features/users/user.model';
import { ImageModel, PageableModel, TimeZoneDTO, UserProductCommissionModel } from '@shared/models';
import { UserProductCommissionRequestModel } from '@shared/models/request-models';
import { HttpHelperService } from './internal/http-helper.service';
import { UserShippingAddress } from '@shared/models/features/users/user-shipping-address';
import { RequestUserShippingAddress } from '@shared/models/request-models/user-shipping-address';
import { IAddUserWithoutInvite, ICommissionOverride, ISalesTargetDTO, ISignUp, IUserSubordinationView } from '@shared/interfaces';
import { IFilter, IUserOrganizationDto, IUserRoleInfo } from '@shared/interfaces';
import { BehaviorSubject, catchError, map, Observable, of, take } from 'rxjs';
import { SalesTarget, SalesTargetActual, UserActions } from '@shared/models/features/users';
import { UserPhysicianModel } from '@shared/models/features/users/user-physician.model';
import { ApiService } from '@shared/classes/api-service';
import { TColorThemes, TCurrency, TDateFormat, TState, TRoles } from '@shared/type/index.type';
import { ConfirmModalConfig, DefaultCurrency, DefaultDateFormat, DefaultPagination } from '@constants';
import { UsersPageableParams } from '@shared/models/build-models/pageable/users-pageable-params';
import { UserAdditionalContactRequestModel, UserAdditionalContactResponse } from '@shared/models/features/users/user-additional-contact';
import { encryptionString } from '@shared/utils/encryption-string';
import { InjectorContainerModule } from '@shared/helpers/injector-container/injector-container.module';
import { LocalStorage } from '@services/internal/localstorage.service';
import { UserSubordinationService } from '@services/user-subordination.service';
import { CustomPermissions, UserLevelPermissions } from '@shared/models/permissions/permission.model';
import { AuthenticationService } from '@services/auth.service';
import { setCustomTheme } from '@shared/utils/change-theme';
import { PermissionService } from '@services/internal/permission.service';
import { GlobalStoreService } from './internal/global-store.service';
import { ConfirmComponent } from '@shared/components/modals/confirm/confirm.component';
import { LanguageService } from '@services/internal/language.service';
import { MatDialog } from '@angular/material/dialog';
import { OfflineStatusService } from '@services/offline/offline-status.service';
import { environment } from '@environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends ApiService {
  private auth = inject(AuthenticationService);
  private permissionService = inject(PermissionService);
  private dialog = inject(MatDialog);
  private offlineStatusService = inject(OfflineStatusService);
  private injector = inject(Injector);
  private userSubordinationService = inject(UserSubordinationService);

  static userTimeZone: UserTimeZone;
  static currency: TCurrency = DefaultCurrency;
  static dateFormat: TDateFormat = DefaultDateFormat;
  static organizations$: BehaviorSubject<IUserOrganizationDto[]> = new BehaviorSubject<IUserOrganizationDto[]>([]);
  static colorTheme$: BehaviorSubject<TColorThemes> = new BehaviorSubject<TColorThemes>(null);
  static defaultPagination: BehaviorSubject<number> = new BehaviorSubject<number>(DefaultPagination);
  static user$: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(new UserModel());
  static isQuickTransfersEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private static subordinators: IUserSubordinationView[] = [];
  private translateService: TranslateService;

  constructor() {
    super();
    if (!GlobalStoreService.isMobile$.value) {
      LocalStorage.getItem('defaultPagination').then(pagination => {
        const pagNumber = Number(pagination);
        if (!isNaN(pagNumber) && pagNumber > 0) {
          UsersService.defaultPagination.next(pagNumber);
        }
      });
    }
  }

  static getUser = (): UserModel => UsersService.user$.value;
  static getCompanyName = (): string => UsersService.user$.value.organization?.company?.name;

  static getUsersForMentions(params: UsersPageableParams): Observable<PageableModel<UserModel>> {
    const service: UsersService = InjectorContainerModule.injector.get(UsersService);
    return service.get<PageableModel<UserModel>>(`users/noSpecPageable`, HttpHelperService.addResponseTypeJSON(params));
  }

  private static setUser(u: UserModel): void {
    const user: UserModel = { ...{}, ...u };
    user.subordinators = UsersService.subordinators;
    UsersService.user$.next(user);

    // Handle users with GRAY color theme
    const colorTheme: TColorThemes = user.colorTheme ? (user.colorTheme === 'GRAY' ? 'DARK' : user.colorTheme) : 'LIGHT';
    UsersService.colorTheme$.next(colorTheme);

    // Set web user id to logout if another user logs in another tab, IsDeviceEffect check it
    localStorage.setItem('userId', encryptionString(user?.id, 3));
  }

  // First login set timezone
  private checkUserTimeZone(user: UserModel): UserModel {
    UsersService.userTimeZone = {
      timeZone: user.timeZone,
      offset: user.offset
    };
    if (!user.timeZone || user.offset === null) {
      UsersService.userTimeZone = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        offset: new Date().getTimezoneOffset() * 60
      };
      user = { ...user, ...UsersService.userTimeZone };
      this.saveMe(user)
        .pipe(take(1))
        .subscribe(() => this.me().pipe(take(1)).subscribe());
    }
    return user;
  }

  me(updateUserDataOnly: boolean = false): Observable<UserModel> {
    return this.get<UserModel>(`users/me`).pipe(
      map(data => {
        if (updateUserDataOnly) {
          UsersService.setUser(data);
          return data;
        }

        // Set it once, the first time
        if (!AuthenticationService.isFakeAuth && !UsersService.user$.value?.id) {
          setCustomTheme(data?.colorTheme);
        }
        if (!data || data?.state === 'INACTIVE' || !data?.id) {
          this.auth.logout();
        }
        if (data.id) {
          if (!this.translateService) {
            this.translateService = this.injector.get(TranslateService);
          }
          // Backend keeps uppercase language codes, core-translation.module uses lowercase
          const lang = data?.language?.toLowerCase();
          if (this.translateService.currentLang !== lang) {
            this.translateService.use(lang);
          }

          const customPermissions: CustomPermissions = this.getCustomPermissions(data);
          this.permissionService.setPermissions(data, customPermissions);
          this.findOrganizationByEmail(data.emailAddress)
            .pipe(take(1))
            .subscribe(organizations => {
              UsersService.organizations$.next(organizations);
            });
          UsersService.setUser(data);
          UsersService.currency = data?.organization?.currency || DefaultCurrency;
          UsersService.dateFormat = data?.organization?.dateFormat || DefaultDateFormat;
          if (
            data.isOffline &&
            !this.offlineStatusService.isOffline$.value &&
            this.offlineStatusService.isNetworkAvailable$.value &&
            GlobalStoreService.getPlatform() === 'web'
          ) {
            this.offlineModeModal();
          }
          //Is allowed to create Quick transfer from assigned custodian only
          UsersService.isQuickTransfersEnabled$.next(
            (data.role === 'SALES' || data.role === 'DISTRIBUTOR' || data.role === 'FACILITY_MANAGER') &&
              data.customPermissions?.transferInventoryFromCustodianOnlyAllowed &&
              this.permissionService.isGranted('custom', 'initiateTransfersEnabled') &&
              this.permissionService.isGranted('inventory', 'createInventoryTransfer')
          );
        }

        if (data.role === 'DISTRIBUTOR') {
          // load subordinators
          this.userSubordinationService
            .getSubordinations([data.id])
            .pipe(take(1))
            .subscribe(assignedusers => {
              UsersService.subordinators = Object.values(assignedusers).flat();
              UsersService.setUser(data);
            });
        }
        return this.checkUserTimeZone(data);
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        this.auth.logout();
        return of(error);
      })
    );
  }

  getDocuments(id: string): Observable<ImageModel[]> {
    return this.get<ImageModel[]>(`users/${id}/attachments`);
  }

  async uploadDocument(id: string, formData: FormData): Promise<boolean> {
    const token: string = AuthenticationService.getToken();

    const res = await window.fetch(`${environment.apiUrlCore}users/${id}/attachment`, {
      method: 'PUT',
      body: formData,
      headers: { Authorization: `Bearer ${token}` }
    });
    if (res.status >= 400 && res.status < 600) {
      this.alertsService.showError('shared.alerts.errorMessages.loadAttachments');
      return Promise.reject();
    } else {
      return true;
    }
  }

  uploadDocumentURL(id: string, imageUrl: string): Observable<boolean> {
    return this.put<string>(`users/${id}/attachment/url`, imageUrl).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deleteDocument(id: string, attachment: string): Observable<boolean> {
    return this.delete<void>(`users/${id}/attachment?userAttachmentId=${attachment}`).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.attachmentWasDeleted')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  addCommission(params: UserProductCommissionRequestModel): Observable<void> {
    return this.post<void>(`users/commissions`, params).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.saved')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updateUserRegion(id: string, regionId: string): Observable<void> {
    return this.put<void>(`users/${id}/region?regionId=${regionId}`, {}).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  assignRegions(userId: string, regionIds: string[]): Observable<boolean> {
    return this.put<void>(`users/${userId}/regionalManager/regions?regionIds=${regionIds}`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.assigned');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.assignRegion', error.message);
        return of(null);
      })
    );
  }

  changePassword(newPassword: string): Observable<void> {
    return this.post<void>(`users/password/change`, newPassword).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.saved')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deactivate(userId: string, status: TState): Observable<void> {
    return this.put<void>(`users/${userId}/state?state=${status}`, {});
  }

  deleteCommission(id: string): Observable<void> {
    return this.delete<void>(`users/commissions/${id}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getAllUsersPageable(params: UsersPageableParams): Observable<PageableModel<UserModel>> {
    return this.get<PageableModel<UserModel>>(`users/pageable`, HttpHelperService.addResponseTypeJSON(params));
  }

  getUsers(params: UsersPageableParams): Observable<UserModel[]> {
    return this.get<UserModel[]>(`users`, HttpHelperService.addResponseTypeJSON(params));
  }

  getCommission(params?: { userId: string }): Observable<UserProductCommissionModel> {
    return this.get<UserProductCommissionModel>(`users/commissions`, { params });
  }

  getUsersByFacility(id: string): Observable<UserModel[]> {
    return this.get<UserModel[]>(`users/${id}/facility-users`);
  }

  getCustody(params: {
    name?: string;
    size?: number;
    state?: TState;
    roles?: string[];
    withQuickTransferUsers?: boolean;
  }): Observable<PageableModel<UserModel>> {
    return this.get<PageableModel<UserModel>>(`users/custody/pageable`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(new PageableModel()))
    );
  }

  getIndividualsPageable(params: { name?: string; size?: number; state?: TState }): Observable<PageableModel<UserModel>> {
    return this.get<PageableModel<UserModel>>(`users/individuals/pageable`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(new PageableModel()))
    );
  }

  getTransferRecipients(params: { name?: string; size?: number; state?: TState }): Observable<PageableModel<UserModel>> {
    return this.get<PageableModel<UserModel>>(`users/transfers/recipients`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(new PageableModel()))
    );
  }

  getTransferSenders(params: { name?: string; size?: number; state?: TState }): Observable<PageableModel<UserModel>> {
    return this.get<PageableModel<UserModel>>(`users/transfers/senders`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(new PageableModel());
      })
    );
  }

  getUser(id: string, redirectToNotFoundPage: boolean = false): Observable<UserModel> {
    return this.get<UserModel>(`users/${id}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        HttpHelperService.errorHandler(error, null, redirectToNotFoundPage);
        this.alertsService.showError('shared.alerts.errorMessages.loadUser');
        return of(null);
      })
    );
  }

  getUserActions(params: IFilter): Observable<PageableModel<UserActions>> {
    return this.get<PageableModel<UserActions>>(`users/trackingAction`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  getUserShippingAddress(userId: string): Observable<UserShippingAddress> {
    return this.get<UserShippingAddress>(`users/${userId}/shippingAddress`);
  }

  addUserWithoutInvite(body: IAddUserWithoutInvite): Observable<boolean> {
    return this.post<void>(`users/signupUserNoInvite`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.success');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  addPhysicianCommission(body: ICommissionOverride): Observable<void> {
    return this.put<void>(`users/physicianCommission`, body).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.saved')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getPhysiciansCommission(userId: string): Observable<UserPhysicianModel[]> {
    return this.get<UserPhysicianModel[]>(`users/${userId}/userPhysician`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  removePhysicianCommission(queryParams: Partial<ICommissionOverride>): Observable<void> {
    return this.put<void>(`users/physicianCommission/deleteCommission`, queryParams).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.deleted')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  postUserShippingAddress(userId: string, queryParams: RequestUserShippingAddress): Observable<void> {
    return this.post<void>(`users/${userId}/shippingAddress`, queryParams).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.saved')),
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.saveShippingAddress');
        return of(null);
      })
    );
  }

  putUserShippingAddress(userId: string, queryParams: RequestUserShippingAddress): Observable<void> {
    return this.put<void>(`users/${userId}/shippingAddress`, queryParams).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.saved')),
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.saveShippingAddress');
        return of(null);
      })
    );
  }

  updateCustomPermissions(userId: string, permissions: UserLevelPermissions): Observable<boolean> {
    return this.put<void>(`users/${userId}/custom-permissions`, permissions).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  resetPassword(params: { email: string; organizationId: string }): Observable<void> {
    return this.post<void>(`users/password/reset`, params).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.linkToResetPasswordWasSent')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  saveMe(body: UserModel): Observable<void> {
    return this.put<void>(`users/me`, body).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.saved')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  saveNewPassword(id: string, password: string): Observable<boolean> {
    return this.post<void>(`users/password/change/${id}`, password).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.passwordWasChanged');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  searchTimeZones(params: { search: string }): Observable<TimeZoneDTO[]> {
    return this.get<TimeZoneDTO[]>(`timeZones`, { params });
  }

  signUp(body: ISignUp): Observable<boolean> {
    return this.post<void>(`users/signup`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.success');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updateAnotherUser(userId: string, body: UserModel): Observable<void> {
    return this.put<void>(`users/${userId}`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  changeUserRole(userId: string, role: TRoles): Observable<void> {
    return this.put<void>(`users/${userId}/role`, JSON.stringify(role)).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.success')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getRoleStatistics(): Observable<IUserRoleInfo[]> {
    return this.get<IUserRoleInfo[]>(`users/roleStatistics`);
  }

  showInventories(userId: string, showinventories: boolean): Observable<void> {
    return this.put<void>(`users/${userId}/showinventories?showinventories=${showinventories}`, {}).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.success')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  createSalesTarget(salesTarget: ISalesTargetDTO): Observable<string> {
    return this.post<string>(`users/targets`, salesTarget).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.success');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  putSalesTarget(targetId: string, salesTarget: ISalesTargetDTO): Observable<void> {
    return this.put<void>(`users/targets/${targetId}`, salesTarget).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getTargetActual(userId: string, year: number): Observable<SalesTargetActual> {
    return this.get<SalesTargetActual>(`users/${userId}/actual/${year}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(new SalesTargetActual());
      })
    );
  }

  getSalesTargets(userId: string): Observable<SalesTarget[]> {
    return this.get<SalesTarget[]>(`users/${userId}/targets`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  findOrganizationByEmail(email: string): Observable<IUserOrganizationDto[]> {
    return this.get<IUserOrganizationDto[]>(`users/${email}/organizations`).pipe(
      map((organizations = []) => organizations.sort((a, b) => a.name.localeCompare(b.name))),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  getUserAdditionalContact(userId: string): Observable<UserAdditionalContactResponse[]> {
    return this.get<UserAdditionalContactResponse[]>(`users/${userId}/userContacts`);
  }

  putAdditionalContact(userId: string, contacts: UserAdditionalContactRequestModel[]): Observable<boolean> {
    return this.put<void>(`users/${userId}/userContacts`, contacts).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.success');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  unLock(userId: string): Observable<boolean> {
    return this.put<void>(`users/${userId}/unlock`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.success');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  toggleCustodianOnly(id: string, isCustodianOnly: boolean): Observable<boolean> {
    return this.put<void>(`users/${id}/custodian?isCustodianOnly=${isCustodianOnly}`, {}).pipe(
      map(() => {
        if (isCustodianOnly) {
          this.alertsService.showSuccess('shared.alerts.successMessages.toggleStandardUser');
        } else {
          this.alertsService.showSuccess('shared.alerts.successMessages.toggleCustodianOnly');
        }
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  changeOfflineMOde(userId: string, params: { makeOffline: boolean; offlineHours: number }): Observable<boolean> {
    return this.put<void>(`users/${userId}/change-user-mode`, params).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  toggleIsPrimaryAdminFlag(userId: string, value: boolean): Observable<boolean> {
    return this.put<void>(`superadmin/${userId}/is-primary-admin?flag=${value}`, {}).pipe(
      map(() => true),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deleteAdditionalContact(userContactId: string): Observable<boolean> {
    return this.delete<void>(`users/userContacts/${userContactId}`).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.success');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  poCanBeSent(userId: string): Observable<boolean> {
    return this.get<boolean>(`users/${userId}/poCanBeSent`);
  }

  // Destroy service calls on GlobalStoreService
  destroy() {
    UsersService.user$.next(null);
    UsersService.organizations$.next([]);
  }

  private getCustomPermissions(user: UserModel): CustomPermissions {
    const applyPermissions: boolean = user.role !== 'ADMIN';
    const customPermissions: CustomPermissions = {
      addAndDeactivateInventoriesEnabled: applyPermissions ? user.organization.addAndDeactivateInventoriesEnabled : true,
      initiateTransfersEnabled: applyPermissions ? user.organization.initiateTransfersEnabled : true,
      reopenEventsEnabled: applyPermissions ? user.organization.reopenEventsEnabled : true,
      requireAssociateRequestsWithEvents: applyPermissions ? user.organization.requireInventoryEnabled : false,
      requireEventNotesEnabled: user.organization.requireEventNotesEnabled
    };
    return customPermissions;
  }

  private offlineModeModal(): void {
    const offlineModeModalDialogRef = this.dialog.open(
      ConfirmComponent,
      ConfirmModalConfig({
        title: LanguageService.instant('offline.offlineModeEnabledModal'),
        description: LanguageService.instant('offline.userInOfflineModeModalDescription'),
        acceptButtonText: LanguageService.instant('offline.disableOfflineModeButtonText')
      })
    );
    offlineModeModalDialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(res => {
        if (res) {
          this.changeOfflineMOde(UsersService.getUser().id, { makeOffline: false, offlineHours: null })
            .pipe(take(1))
            .subscribe(() => {
              this.alertsService.showSuccess('shared.alerts.successMessages.saved');
            });
        }
      });
  }
}
