import { EPreferredShipping } from '@shared/enum';
import { TFacilityContactsType } from '@shared/type/index.type';

export class ShipToModel {
  id?: string;
  streetAddress: string;
  cityAddress: string;
  stateAddress: string;
  zipCode: string;
  preferredShippingType: keyof typeof EPreferredShipping;
  // Front-end property
  type?: TFacilityContactsType;
  userShipment?: boolean;
  optionalAddress: string;
  country: string;
  googlePlaceId?: string;
}
