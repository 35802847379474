import { AfterViewInit, Component, inject, signal, WritableSignal, ApplicationRef, effect } from '@angular/core';
import { environment } from '@environment';
import { SwUpdate } from '@angular/service-worker';
import { AlertsService } from '@services/internal/alerts.service';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { GlobalStoreService } from '@services/internal/global-store.service';
import { LanguageService } from '@services/internal/language.service';
import { AnalyticsService } from '@services/internal/analytics.service';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { AppUpdateAvailability } from '@capawesome/capacitor-app-update/dist/esm/definitions';
import { GlobalErrorHandlerService } from '@services/internal/global-error-handler.service';
import { OfflineStatusService } from '@services/offline/offline-status.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false
})
export class AppComponent extends DestroySubscriptions implements AfterViewInit {
  private swUpdate = inject(SwUpdate);
  private alertsService = inject(AlertsService);
  private router = inject(Router);
  private appRef = inject(ApplicationRef);
  private analyticsService = inject(AnalyticsService);
  private globalErrorHandlerService = inject(GlobalErrorHandlerService);
  /** inject globalStoreService to set page title on non auth pages */
  //@ts-ignore
  private globalStoreService = inject(GlobalStoreService);
  /** inject LanguageService to make multilanguage work */
  //@ts-ignore
  private languageService = inject(LanguageService);
  private offlineStatusService = inject(OfflineStatusService);

  isOffline$ = this.offlineStatusService.isOffline$;
  /** Allow render UI for users that added correct password */
  allowRender: WritableSignal<boolean> = signal<boolean>(false);
  /** Password that users added for non-production environments */
  password: WritableSignal<string> = signal<string>('');
  platform = GlobalStoreService.getPlatform();
  /** Update on swipe to top on iOS/android */
  pullDistance = signal<number>(0);
  minPullDistance = signal<number>(150);

  isAISidebarOpen = GlobalStoreService.isAISidebarOpen;

  private startY: number = 0;
  private isPullingDown: boolean = false;
  private isAtTop: boolean = false;

  sidebarWidth = signal<number>(320);
  private resizing: boolean = false;
  private startX: number = 0;
  private startWidth: number = 320;
  private widgetResizeSheet: CSSStyleSheet | null = null;
  private messagingContainer: HTMLElement;

  constructor() {
    super();
    /** Show input to enter password for non-production environments */ // @ts-ignore
    if (AppComponent.isProd() || location.port === '4200' || GlobalStoreService.getPlatform() !== 'web' || window.Cypress) {
      this.allowRender.set(true);
    } else {
      if (AppComponent.getCookie('dev_password') === environment.password) {
        this.allowRender.set(true);
      }
    }
    if (GlobalStoreService.getPlatform() !== 'web') {
      this.initializeDeepUrlsWatcher();
    }

    /** Open AI search sidebar second time, set messaging position */
    if (this.platform !== 'ios') {
      effect(() => {
        if (GlobalStoreService.isAISidebarOpen() && this.messagingContainer) {
          this.messagingContainer.style.right = `${this.sidebarWidth() + 64}px`;
        }
      });
    }
  }

  static isProd = (): boolean => environment.production && location.host === 'beacon.connectsx.com';

  static insertAISearchScriptWidget(): void {
    if (GlobalStoreService.getPlatform() === 'ios') return;
    if (document.querySelector('gen-search-widget')) return;

    const script = document.createElement('gen-search-widget');
    script.setAttribute('configId', '5e558c0a-3b74-4613-9bbf-6b54e732d857');
    script.setAttribute('triggerId', 'searchWidgetTrigger');
    script.setAttribute('anchorsTarget', '_blank');
    if (GlobalStoreService.isMobile$.value) {
      document.body.appendChild(script);
    } else {
      document.getElementById('widget-container').appendChild(script);
    }
  }

  ngAfterViewInit(): void {
    AppComponent.insertAISearchScriptWidget();

    if (this.platform === 'web') {
      AppComponent.initDefaultTrustedType();
      /** Check Service Worker updates to reload the page and apply changes */
      if (this.swUpdate.isEnabled) {
        this.swUpdate.versionUpdates.pipe(takeUntil(this.subscriptions)).subscribe(evt => {
          if (evt.type === 'VERSION_READY') {
            this.swUpdate.activateUpdate().then(() => {
              this.alertsService.showWarning('shared.alerts.successMessages.newVersionAvailable');
              document.location.reload();
            });
          }
        });
      }
      if (AppComponent.isProd()) {
        this.analyticsService.initGA();
        console.log('%cSTOP!', 'color:red; font-size: 36px; font-weight: bold; text-shadow: 1px 1px 1px black; background: yellow;');
        console.log(
          '%cUsing this console may allow attackers to impersonate you and steal your information using an attack called Self-XSS. ' +
            'Do not enter or paste code that you do not understand.',
          'font-size: 18px;'
        );
      }
    } else {
      if (environment.production) {
        (async () => {
          try {
            const updateInfo = await AppUpdate.getAppUpdateInfo();
            if (updateInfo.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE) {
              await AppUpdate.openAppStore();
            }
          } catch (e: any) {
            this.globalErrorHandlerService.reportFrontEndInternalErrorsToSlack({ ...{ message: 'Capacitor App Update Error' }, ...e }, {});
          }
        })();
      }
    }
  }

  onResizeStart(event: MouseEvent): void {
    this.resizing = true;
    this.startX = event.clientX;
    this.startWidth = this.sidebarWidth();
    if (!this.messagingContainer) {
      this.messagingContainer = document.getElementById('messagingContainer');
    }

    window.addEventListener('mousemove', this.onResizing);
    window.addEventListener('mouseup', this.onResizeEnd);
  }

  onResizing = (event: MouseEvent): void => {
    if (!this.resizing) return;

    const dx = this.startX - event.clientX;
    const rawWidth = this.startWidth + dx;

    const maxWidth = window.innerWidth * 0.8;
    this.sidebarWidth.set(Math.min(Math.max(rawWidth, this.startWidth), maxWidth));

    const widget = document.querySelector('gen-search-widget') as HTMLElement;

    if (widget?.shadowRoot) {
      const shadow = widget.shadowRoot;

      if (!this.widgetResizeSheet) {
        this.widgetResizeSheet = new CSSStyleSheet();
        shadow.adoptedStyleSheets = [...shadow.adoptedStyleSheets, this.widgetResizeSheet];
      }

      this.widgetResizeSheet.replaceSync(`
        div[role=dialog] {
          width: ${this.sidebarWidth()}px !important;
        }
      `);

      this.messagingContainer.style.right = `${this.sidebarWidth() + 64}px`;
    }
  };

  onResizeEnd = (): void => {
    this.resizing = false;
    window.removeEventListener('mousemove', this.onResizing);
    window.removeEventListener('mouseup', this.onResizeEnd);
  };

  /** Check password user added for non-production environments */
  checkPassword(): void {
    if (environment.password === this.password()) {
      this.allowRender.set(true);
      const expiryDate: Date = new Date();
      expiryDate.setMonth(expiryDate.getMonth() + 1);
      document.cookie = `dev_password=${this.password()};expires=${expiryDate.toUTCString()}`;
    }
  }

  /** Refresh page on swipe to top works on iOS/android */
  onTouchStart(event: TouchEvent): void {
    if (this.isOffline$.value) {
      return;
    }
    this.startY = event.touches[0].clientY;
    this.isAtTop = window.scrollY === 0;
    this.pullDistance.set(0);
  }

  onTouchMove(event: TouchEvent): void {
    if (this.isOffline$.value) {
      return;
    }
    const currentY = event.touches[0].clientY;
    this.pullDistance.set(currentY - this.startY);
    if (this.isAtTop) {
      this.isPullingDown = this.pullDistance() > 0;
    }
  }

  onTouchEnd(): void {
    if (this.isOffline$.value) {
      return;
    }
    if (this.isAtTop && this.isPullingDown && this.pullDistance() >= this.minPullDistance()) {
      window.location.reload();
    } else {
      this.resetPull();
    }
  }

  resetPull(): void {
    this.isPullingDown = false;
    this.isAtTop = false;
    this.pullDistance.set(0);
  }
  /** End reload on swipe to top */

  /** CheckPassword in cookies for non-production environments */
  private static getCookie(name: string): string {
    const matches: string[] = document.cookie.match(
      new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
    );
    return matches ? decodeURIComponent(matches[1]) : null;
  }

  /** Default TrustedType */
  private static initDefaultTrustedType(): void {
    // @ts-ignore
    if (window.trustedTypes?.createPolicy) {
      // @ts-ignore
      window.trustedTypes.createPolicy('default', {
        createHTML: (str: string) => str.replace(/\</g, '&lt;'),
        createScriptURL: (str: string) => str, // warning: this is unsafe!
        createScript: (str: string) => str
      });
    }
  }

  private initializeDeepUrlsWatcher() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const slug = event.url.split('connectsx.com').pop();
      if (slug?.length) {
        this.appRef.tick();
        this.router.navigateByUrl(slug);
      }
    });
  }
}
