export enum EIntegrationEndgrateTransferStatus {
  OPEN = 'shared.enums.integrationEndgrateTransferStatus.open',
  READY = 'shared.enums.integrationEndgrateTransferStatus.READY',
  IN_PROGRESS = 'shared.enums.integrationEndgrateTransferStatus.IN_PROGRESS',
  COMPLETED = 'shared.enums.integrationEndgrateTransferStatus.COMPLETED',
  COMPLETED_WITH_ERROR = 'shared.enums.integrationEndgrateTransferStatus.COMPLETED_WITH_ERROR',
  ERROR = 'shared.enums.integrationEndgrateTransferStatus.ERROR'
}

export enum EEndgrateIntegrationType {
  INVENTORY = 'shared.enums.integrationEndgradeType.INVENTORY',
  SHIPPING_ADDRESS = 'shared.enums.integrationEndgradeType.SHIPPING_ADDRESS',
  FACILITY = 'shared.enums.integrationEndgradeType.FACILITY',
  CATALOG = 'shared.enums.integrationEndgradeType.CATALOG',
  PHYSICIAN = 'shared.enums.integrationEndgradeType.PHYSICIAN',
  PRODUCT = 'shared.enums.integrationEndgradeType.PRODUCT'
}

export enum EEngrateSessionType {
  PULL = 'shared.enums.endgrateSessionType.PULL',
  PUSH = 'shared.enums.endgrateSessionType.PUSH'
}

export enum EEngrateScheduleType {
  MANUAL = 'shared.enums.engrateScheduleType.MANUAL',
  CRON_15_MIN = 'shared.enums.engrateScheduleType.CRON_15_MIN'
}
