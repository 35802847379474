@if (allowRender()) {
  <mat-sidenav-container>
    <mat-sidenav mode="side" [opened]="isAISidebarOpen()" position="end" [style.width.px]="sidebarWidth()">
      <div id="widget-container"></div>
      <div class="resize-handle" (mousedown)="onResizeStart($event)"></div>
    </mat-sidenav>

    <mat-sidenav-content [style.margin-right]="isAISidebarOpen() ? sidebarWidth() + 'px' : null" id="mat-sidenav-content">
      @if (platform === 'web') {
        <router-outlet />
      } @else {
        <div class="refresh-container" (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd()">
          @if (pullDistance() > 0) {
            <div class="refresh-icon">
              <mat-progress-spinner [value]="(pullDistance() * 100) / minPullDistance()" mode="determinate" diameter="30" />
            </div>
          }
          <router-outlet />
        </div>
      }
    </mat-sidenav-content>
  </mat-sidenav-container>
} @else {
  <div class="devGuardBg">
    <app-input-kit
      [dataCy]="'welcomePassword'"
      [required]="true"
      [type]="'password'"
      [initialValue]="password()"
      [label]="'Password'"
      (valueChangedEmitter)="password.set($event)"
      (enterEmitter)="checkPassword()"
    />
  </div>
}
