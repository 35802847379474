import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  DeactivationDataModel,
  EventContainerModel,
  InventoryCountByCatalog,
  InventoryModel,
  NoteModel,
  PageableModel,
  SimilarInventoryFilter,
  SimilarInventoryInfo,
  UDIModel
} from '@shared/models';
import { HttpHelperService } from './internal/http-helper.service';
import { AuthenticationService } from './auth.service';
import { ImageModel } from '@shared/models/shared/image.model';
import { InventoryPageableParams, InventoryRequestBuild } from '@shared/models/build-models';
import { catchError, map, Observable, of } from 'rxjs';
import { ApiService } from '@shared/classes/api-service';
import { IExpiredStatistics } from '@shared/interfaces/dashboard/expired-statistics';
import { environment } from '@environment';
import { IFilter } from '@shared/interfaces';
import { InspectionModel } from '@shared/models/features/inventory/inventories/inspection';
import { FutureEventsModel } from '@shared/models/features/inventory/inventories/future-events';
import { IAvailableContainer, IAvailableContainerParams } from '@shared/interfaces/inventory/available-container';
import { IAutoRefillAllParamsModel, IAutoRefillResponseAutoRefillResponse } from '@shared/interfaces/inventory/auto-refill';
import { InventoryPackRequestBuild } from '@shared/models/build-models/inventory/inventory-pack-request-build';

@Injectable({
  providedIn: 'root'
})
export class InventoriesService extends ApiService {
  getDeactivationData(id: string): Observable<DeactivationDataModel> {
    return this.get<DeactivationDataModel>(`inventories/${id}/user-inventory-deactivation`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getFutureEvents(params: { dateTimeFrom: string; dateTimeTo: string }): Observable<FutureEventsModel[]> {
    return this.get<FutureEventsModel[]>(`inventories/containers/future-events`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  getReservedEvents(params: { dateTimeFrom: string; dateTimeTo: string }): Observable<FutureEventsModel[]> {
    return this.get<FutureEventsModel[]>(`inventories/containers/reserved-events`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  changeReservedForEvent(inventoryId: string, eventId: string): Observable<boolean> {
    return this.put<void>(`inventories/${inventoryId}/edit-reserved-event` + (eventId ? `?eventId=${eventId}` : ''), {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getExpirationsByCustody(params: InventoryPageableParams): Observable<PageableModel<IExpiredStatistics>> {
    return this.get<PageableModel<IExpiredStatistics>>(`inventories/expiredStatistics?page=${params.page}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(new PageableModel());
      })
    );
  }

  getAvailableContainers(params: IAvailableContainerParams): Observable<IAvailableContainer[]> {
    return this.get<IAvailableContainer[]>(`inventories/available-containers`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  getInventoryForAutoRefill(params: IAutoRefillAllParamsModel[]): Observable<IAutoRefillResponseAutoRefillResponse[]> {
    return this.post<IAutoRefillResponseAutoRefillResponse[]>(`inventories/inventoryForAutoRefill`, params).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  assignToContainer(id: string, deviceIds: string[], showSuccessAlert: boolean = true): Observable<boolean> {
    return this.post<void>(`inventories/${id}/inventory/assign`, deviceIds).pipe(
      map(() => {
        if (showSuccessAlert) {
          this.alertsService.showSuccess('shared.alerts.successMessages.assigned');
        }
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.assignContainer', error.message);
        return of(null);
      })
    );
  }

  deactivate(id: string): Observable<boolean> {
    return this.post<void>(`inventories/${id}/deactivate`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.deactivated');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deleteImage(inventoryId: string, imageId: string): Observable<void> {
    return this.delete<void>(`inventories/${inventoryId}/image?inventoryImageId=${imageId}`).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.imageWasDeleted')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  export(params: InventoryPageableParams): Promise<any> {
    const param: string = HttpHelperService.getQueryParams(params);
    const token: string = AuthenticationService.getToken();
    return window
      .fetch(`${environment.apiUrlCore}inventories/export?${param}`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => {
        this.alertsService.showSuccess('shared.alerts.successMessages.inventoryExportWasStarted');
        return res;
      })
      .catch(() => {
        this.alertsService.showError('shared.alerts.errorMessages.exportInventory');
      });
  }

  getInventories(id: string, redirectToNotFoundPage: boolean = false): Observable<InventoryModel> {
    return this.get<InventoryModel>(`inventories/${id}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        HttpHelperService.errorHandler(error, null, redirectToNotFoundPage);
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getCatalogStats(inventoryId: string): Observable<Map<string, number>> {
    return this.get<Map<string, number>>(`inventories/${inventoryId}/catalogStats`);
  }

  getCOG = (params: InventoryPageableParams): Observable<string> =>
    this.get<number>(`inventories/value/cog`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of('0'))
    );

  getInventoriesToTransfer(params: InventoryPageableParams): Observable<PageableModel<InventoryModel>> {
    return this.get<PageableModel<InventoryModel>>(`inventories/transfers/search`, HttpHelperService.addResponseTypeJSON(params));
  }

  getInventoriesToTransferGrouped(
    params: SimilarInventoryFilter,
    size: number,
    isQuickTransfer: boolean = false
  ): Observable<SimilarInventoryInfo[]> {
    return this.post<SimilarInventoryInfo[]>(
      `inventories/transfers/search/grouped?size=${size}&isQuickTransfer=${isQuickTransfer}`,
      params
    ).pipe(catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of([])));
  }

  getInventoryImages(inventoryId: string): Observable<ImageModel[]> {
    return this.get<ImageModel[]>(`inventories/${inventoryId}/images`);
  }

  getAssociatedEvents(inventoryId: string): Observable<EventContainerModel[]> {
    return this.get<EventContainerModel[]>(`inventories/${inventoryId}/eventContainers`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getPageable(params: InventoryPageableParams): Observable<PageableModel<InventoryModel>> {
    return this.get<PageableModel<InventoryModel>>(`inventories/pageable/v1`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(new PageableModel()))
    );
  }

  getPageableQuickTransfer(params: InventoryPageableParams): Observable<PageableModel<InventoryModel>> {
    return this.get<PageableModel<InventoryModel>>(
      `inventories/pageable/quick-transfer`,
      HttpHelperService.addResponseTypeJSON(params)
    ).pipe(catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(new PageableModel())));
  }

  getPageableOld(params: InventoryPageableParams): Observable<PageableModel<InventoryModel>> {
    return this.get<PageableModel<InventoryModel>>(`inventories/pageable`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(new PageableModel()))
    );
  }

  getPageableValue = (params: InventoryPageableParams): Observable<string> =>
    this.get<string>(`inventories/value`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of('0'))
    );

  parseUDI(barcode: string, showAlert: boolean = false): Observable<UDIModel> {
    return this.get<UDIModel>(`udi/parse?barcode=${barcode}`).pipe(
      map(udi => {
        if (showAlert) {
          this.alertsService.showSuccess('shared.alerts.successMessages.success');
        }
        return udi;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        if (showAlert) {
          this.alertsService.showError('shared.alerts.errorMessages.parseUDI');
        }
        return of(error);
      })
    );
  }

  createInventory(body: InventoryRequestBuild, skipAlert: boolean = false): Observable<string[]> {
    return this.post<string[]>(`inventories`, body).pipe(
      map(ids => {
        if (!skipAlert) {
          this.alertsService.showSuccess('shared.alerts.successMessages.created');
        }

        return ids;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  createPack(body: InventoryPackRequestBuild): Observable<string[]> {
    return this.post<string[]>(`inventories/pack`, body).pipe(
      map(ids => {
        this.alertsService.showSuccess('shared.alerts.successMessages.created');
        return ids;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updateInventory(id: string, body: InventoryRequestBuild, showMessage: boolean = true): Observable<boolean> {
    return this.put<void>(`inventories/${id}`, body).pipe(
      map(() => {
        if (showMessage) {
          this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        }
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updatePack(isUsedInPack: boolean, ids: string[]): Observable<boolean> {
    return this.put<void>(`inventories/pack?isUsedInPack=${isUsedInPack}`, ids).pipe(
      map(() => true),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  putImageUrl(inventoryId: string, imageUrl: string): Observable<void> {
    return this.put<void>(`inventories/${inventoryId}/image/url`, imageUrl).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  async uploadImage(inventoryId: string, formData: FormData): Promise<boolean> {
    const token: string = AuthenticationService.getToken();

    const res = await window.fetch(`${environment.apiUrlCore}inventories/${inventoryId}/image`, {
      method: 'PUT',
      body: formData,
      headers: { Authorization: `Bearer ${token}` }
    });
    if (res.status === 200) {
      Promise.resolve(res).then();
      this.alertsService.showSuccess('shared.alerts.successMessages.imageWasUploaded');
      return true;
    } else {
      this.alertsService.showError('shared.alerts.errorMessages.loadImages');
      return false;
    }
  }

  devicesListPDF(inventoryId: string, withImages: boolean = false): Observable<string> {
    return this.post<string>(`inventories/${inventoryId}/pdf?withImages=${withImages}`, {}, HttpHelperService.addResponseTypeText({})).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  devicesListCSV(inventoryId: string): Observable<string> {
    return this.post<string>(`inventories/${inventoryId}/csv`, {}, HttpHelperService.addResponseTypeText({})).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getByCatalog = (params: {
    custodyId: string;
    sortCatalogByASC?: boolean;
    sortInventoryByASC?: boolean;
  }): Observable<InventoryCountByCatalog[]> =>
    this.get<InventoryCountByCatalog[]>(`inventories/byCatalog`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );

  toggleRequireInspection(id: string, value: boolean): Observable<boolean> {
    return this.put<void>(`inventory/inspections/${id}/isInspectionRequired?isInspectionRequired=${value}`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  bulkReleaseDevices(containerId: string, deviceIds: string[]): Observable<boolean> {
    return this.put<void>(`inventories/${containerId}/unassign-from-container`, deviceIds).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.released');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getInspections(id: string, params: IFilter): Observable<PageableModel<InspectionModel>> {
    return this.get<PageableModel<InspectionModel>>(
      `inventory/inspections/${id}/pageable`,
      HttpHelperService.addResponseTypeJSON(params)
    ).pipe(catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(new PageableModel())));
  }

  updateInspection(id: string, params: Partial<InspectionModel>): Observable<boolean> {
    return this.put<void>(
      `inventory/inspections/${id}/inspection?interval=${params.interval}
    &inspectionDate=${params.inspectionDate}`,
      {}
    ).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  markInspected(id: string): Observable<boolean> {
    return this.post<string[]>(`inventory/inspections/${id}/markInspected`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  addInspectionComment(id: string, message: string, userIds: string[]): Observable<boolean> {
    return this.post<string[]>(`inventory/inspections/${id}/comments?mentionUserIds=${userIds}`, message).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getInspectionComments(id: string): Observable<NoteModel[]> {
    return this.get<NoteModel[]>(`inventory/inspections/${id}/comments`).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.loadNotes');
        return of([]);
      })
    );
  }

  changePackDevicesConsumedStatus(deviceIds: string[], isConsumed: boolean): Observable<boolean> {
    return this.put<void>(`inventories/pack/consumed-status?isConsumed=${isConsumed}`, deviceIds).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }
}
