import { CatalogModel } from '@shared/models/features/inventory/catalog/catalog.model';
import { ManufacturerModel } from '@shared/models/features/directory/manufacturers/manufacturer.model';
import { TFacilityGeographicalType, TFacilityPaymentTerms, TState } from '@shared/type/index.type';
import { EFacilityType } from '@shared/enum';
import { ContactModel, LocationModel, PriceSheetModel, UserModel } from '@shared/models';
import { DateTimeString } from '@shared/models/shared/shared-kernel';

export class FacilityModel {
  additionalPrimaryContacts: ContactModel[] = [];
  addressState: string;
  customerId: string;
  billingContact: ContactModel;
  city: string;
  contractRenewalDate: string;
  contractRenewalDateChangedBy: UserModel;
  contractRenewalDateChangedOn: string;
  createdBy: UserModel;
  createdDatetime: DateTimeString;
  externalId: string;
  facilityPricingSheet: PriceSheetModel;
  geographicalType: TFacilityGeographicalType;
  hasFacilityPricing: boolean;
  id: string;
  modifiedBy: UserModel;
  modifiedDatetime: DateTimeString;
  name: string;
  organizationId: string;
  paymentAddressState: string;
  paymentCity: string;
  paymentStreetAddress: string;
  paymentTerm: TFacilityPaymentTerms;
  paymentZipCode: string;
  poNumberEmailsEnabled: boolean;
  primaryContact: ContactModel;
  state: TState;
  streetAddress: string;
  zipCode: string;
  facilityType: keyof typeof EFacilityType;
  removable?: boolean;
  facilityContacts?: ContactModel[];
  isCompletedContract?: boolean;
  xeroCustomerId: string;
  useAlternateCatalogs: boolean;
  isCreditHold: boolean;
  pricingUpdatedOn: DateTimeString;
  paymentGooglePlaceId: string;
  paymentOptionalAddress: string;
  paymentCountry: string;
  country: string;
  optionalAddress: string;
  googlePlaceId: string;
}

export class CatalogParLevelDTOModel {
  catalog: CatalogModel;
  disabled?: boolean;
  location: LocationModel;
  manufacturer?: ManufacturerModel;
  parLevel: number | string;
}
