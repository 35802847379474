import { PermissionModel } from '@shared/models';
import { MergeModels } from '../../merge-models';
import { ECompanyTypes } from '@shared/enum';

export class ManufacturerDistributorRole {
  model: PermissionModel = {
    config: {
      companyType: ECompanyTypes.MANUFACTURER,
      roleName: 'DISTRIBUTOR',
      validRole: true,
      permissionsWasSet: true,
      offlineMode: true
    },
    common: {
      visitDetailPageAssignedEntity: true,
      addComment: true
    },
    dashboard: {
      filterByRepresentative: true,
      readSalesByRepresentativeWidget: true,
      readSalesTargetsWidget: true,
      readSalesTargetWidget: true
    },
    forbiddenRoutes: [
      '/integrations',
      '/directory/regions',
      '/directory/price-lists',
      '/organization-tree',
      '/statistics/PHYSICIANS',
      '/statistics/FACILITIES',
      '/statistics/USERS',
      '/statistics/PARTIALLY_FULFILLED_REQUESTS',
      '/statistics/INVENTORY_UTILIZATION',
      '/statistics/TRANSFERS',
      '/statistics/PRODUCT_LINE',
      '/statistics/CATALOGS'
    ],
    events: {
      canCloseEvent: true,
      canSignEvent: true,
      canAddPONumber: true,
      canChangeEventStatus: true,
      canScheduleEvent: true,
      canEditEvent: true,
      filterByRepresentativeInHisGroup: true,
      canReadUsers: true,
      toggleCalendarView: true,
      filterEvents: true,
      createEvent: true,
      seeImages: true,
      editPriceAdjustments: true,
      readTotalValue: true,
      signEventUsingCanvas: true,
      deviceUsedBulkActions: true,
      submitForBillingRequestReplenishment: true,
      useAdditionalFiltersOnMUI: true,
      copyEvent: true,
      editPONUmber: true
    },
    settings: {
      editSelfIndividualAccountInfo: true
    },
    report: {
      readReport: true,
      readCaseReport: true,
      readCustomSalesReport: true,
      readTotalSalesReport: true,
      inventoryReport: true,
      exportInventory: true
    },
    user: {
      readUserCommission: true,
      addUsersToSystem: true,
      canEditShippingAddress: true,
      canReadUsers: true,
      canDeactivateUsers: true
    },
    inventory: {
      scanViaUDIdentify: true,
      createInventoryRequestWithManufacturerWithoutEmail: true,
      createInventoryTransfer: true,
      canEditInventoryAdditionalInfo: true,
      canEditInventoryPriceInfo: true,
      canReadInventoryImport: true,
      canMarkInventoryAsUsedInEvent: true,
      canEditPriceOfInventoryInDUT: true,
      canAdd_IFY_ToProductLine: true,
      canMakeTransferFromIndividualToOrganization: true,
      filterByCustodyInHisGroup: true,
      createInventory: true,
      canInitiateInventoryRequest: true,
      canCreateInventoryRequestToOrgOnly: true,
      canFilterDevicesByCustody: true,
      canFilterInventoriesByCustody: true,
      approveAnyTransfer: true,
      filterTransfersBySender: true,
      filterTransferByRecipient: true,
      readInventoryValue: true,
      filterInventories: true,
      readCatalogDetail: true,
      readProductLineDetail: true,
      readImportDetail: true,
      readEventDetail: true,
      markDeviceInEvent: true,
      createBOM: true
    },
    movements: {
      createLocation: true,
      createRequestRecipe: true
    },
    directory: {
      createFacility: true,
      createPhysician: true,
      createProcedure: true,
      editFacility: true,
      editPhysician: true,
      editProcedure: true,
      canAddNotesToFacility: true,
      canAddNotesToPhysician: true,
      canAddNotesToProcedure: true,
      canEditSelfOnlyProcedure: true,
      readFacilityDetail: true,
      readPhysicianDetail: true,
      readProcedureDetail: true,
      readManufacturerDetail: true
    }
  };

  constructor() {
    this.model = MergeModels(this.model);
  }
}
